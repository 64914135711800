import { plugin, defaultResolvers, useRenderer } from '@marvr/storyblok-rich-text-vue-renderer'
import { NodeTypes } from '@marvr/storyblok-rich-text-types'
import { identity, omit, pickBy } from 'lodash-es'
import slug from 'slug'
import type { VNode } from 'vue'
import { defu } from 'defu'
import { UText, ULink, NuxtImg } from '#components'
import type { UExpansionPanelStoryblok } from '~/storyblok-component-types'

function extractIdOrText(data: any[]): { id: string, text: string } | null {
  if (!Array.isArray(data) || data.length === 0) {
    return null
  }

  const firstItem = data[0]

  if (!firstItem.children) {
    return null
  }

  const text = typeof firstItem.children === 'string' ? firstItem.children : ''
  const id = slug(firstItem.props && firstItem.props.id ? firstItem.props.id : text)

  const nestedObject = extractIdOrText(firstItem.children)

  return { text, id, ...nestedObject }
}

export default defineNuxtPlugin(app => {
  app.vueApp.use(plugin({
    omitParagraphInListItems: false,
    resolvers: {
      ...defaultResolvers,
      [NodeTypes.PARAGRAPH]: ({ children }) => h(UText, {
        tag: 'p',
        class: '[&:not(:last-child)]:mb-6',
      }, () => children),
      [NodeTypes.UL_LIST]: ({ children }) => h(UText, {
        tag: 'ul',
        class: 'list-disc [&:not(:last-child)]:mb-6',
      }, () => children),
      [NodeTypes.OL_LIST]: ({ children, attrs }) => h(UText, {
        ...attrs,
        tag: 'ol',
        class: 'list-decimal [&:not(:last-child)]:mb-6',
      }, () => children),
      [NodeTypes.LIST_ITEM]: ({ children }) => h('li', {
        class: 'ms-6',
      }, children),
      [NodeTypes.HEADING]: ({ children, attrs }) => {
        const tag = `h${attrs.level}`
        const classes = {
          1: '',
          2: 'text-18 sm:text-22 lg:text-36 font-galano',
          3: 'text-18 sm:text-22 lg:text-28',
          4: 'text-16 sm:text-22 lg:text-22',
          5: 'text-14',
          6: 'text-12',
        }[attrs.level]

        return h(UText, {
          ...(extractIdOrText(children)),
          tag,
          color: '#27292E',
          class: ['font-bold [&:not(:last-child)]:mb-6', classes],
        }, () => children)
      },
      [NodeTypes.LINK]: ({ text, attrs }) => {
        const { getLink } = useLinkType()
        const { anchor, custom, ..._attrs } = pickBy(omit(attrs, ['uuid']), identity)
        const link = getLink(_attrs)
        const linkProps = defu({
          ...(_attrs.linktype === 'story'
            ? {
                to: _attrs.story
                  ? {
                      path: link.props?.to,
                      ...(anchor && { hash: `#${anchor}` }),
                    }
                  : link.props?.href,
              }
            : {
                href: `${link.props?.href?.href ?? link.props?.href}${anchor ? `#${anchor}` : ''}`,
              }),
        }, link.props)

        return h(ULink, {
          ...linkProps,
          color: 'link-primary',
          style: {
            '--v-btn-size': 'inherit',
          },
          inline: true,
          active: true,
        }, () => text)
      },
      [NodeTypes.IMAGE]: ({ attrs }) => h(NuxtImg, {
        src: attrs.src,
        alt: attrs.alt,
        sizes: 'sm:100vw',
        class: 'block aspect-auto w-full',
        provider: 'storyblok',
        densities: 'x1 x2',
        loading: 'lazy',
        draggable: false,
        preload: true,
      }),
      [NodeTypes.QUOTE]: ({ children }) => h(UText, {
        tag: 'blockquote',
        color: 'type-interactive',
        class: 'p-6 sm:px-10 sm:py-8 rounded-6 bg-[rgb(var(--v-theme-type-interactive),.12)] [&:not(:last-child)]:mb-6',
      }, () => children),
      components: {
        uBtn: ({ fields, component }) => {
          const { getLink } = useLinkType()
          const props = pickBy(omit(fields.url, ['linktype', 'cached_url', 'fieldtype', 'url']), identity)

          return h('div', {
            class: '[&:not(:last-child)]:mb-6',
          }, [
            h(resolveComponent(component), {
              ...getLink(fields.url).props,
              ...props,
              text: fields.text,
              color: fields.color,
              variant: fields.variant,
              pro: fields.pro,
              inverted: fields.inverted,
              size: fields.size,
              prependIcon: fields.prependIcon,
              appendIcon: fields.appendIcon,
            }),
          ])
        },
        uTable: ({ fields, component }) => {
          function parseCSVToStructuredObject(fileContent: string) {
            const rows = fileContent.split('\n').filter(Boolean).map(row => row.split('\t'))
            const structuredObject = {
              thead: [] as Record<never, string>[],
              tbody: [] as Record<never, string>[],
            }

            if (rows.length > 0) {
              structuredObject.thead = rows[0].map(header => ({ value: header }))
            }

            for (let i = 1; i < rows.length; i++) {
              const row = { body: rows[i].map(cell => ({ value: cell })) }
              structuredObject.tbody.push(row)
            }

            return structuredObject
          }

          const content = parseCSVToStructuredObject(fields.pasteContent)

          return h(resolveComponent(component), {
            content: fields.pasteContent ? content : fields.content,
            description: fields.description,
            hideHeader: fields?.hideHeader,
            isVertical: fields?.isVertical,
            class: '[&:not(:last-child)]:mb-6',
          })
        },
        UExpansionPanels: ({ fields, component }) => {
          const renderer = useRenderer()

          return h('div', {
            class: '[&:not(:last-child)]:mb-6',
          }, [
            h(resolveComponent(component), null, {
              default: () => fields.body.map((panel: UExpansionPanelStoryblok) => h(resolveComponent(panel.component), {
                title: panel.title,
              }, () => renderer.renderDocument(panel.description as any) as VNode[])),
            }),
          ])
        },
        ctaWidget: ({ fields, component }) => h('div', {
          class: [
            'sm:[&:not(:last-child)]:mb-6',
            {
              'max-sm:sticky max-sm:bottom-4': fields.position === 'bottom',
              'max-sm:hidden': fields.position !== 'bottom',
            },
          ],
        }, [
          h(resolveComponent(component), {
            ...fields,
            class: 'sm:max-w-[75%] md:max-w-[60%] mx-auto',
          }),
        ]),
        compoundCalculator: ({ component }) => h('div', {
          class: 'sm:[&:not(:last-child)]:mb-6',
        }, [
          h(resolveComponent(component)),
        ]),
      },
    },
  }))
})
