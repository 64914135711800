import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_KAumv2pUni from "/app/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import vuetify_client_hints_client_umMQneJmAK from "/app/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-client-hints.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/app/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/app/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_hBLWEPkA3b from "/app/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import nuxt_plugin_6wEQMY3tee from "/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import vuetify_U7abQ5TEyK from "/app/node_modules/@unbiased/uk-nuxt-core/layers/forms/app/plugins/vuetify.ts";
import preview_jcpfaQH7hz from "/app/node_modules/@unbiased/uk-nuxt-core/app/plugins/preview.ts";
import vuetify_b8nXvi6Hh9 from "/app/node_modules/@unbiased/nuxt-ui/app/plugins/vuetify.ts";
import datadog_client_7ANTQbYUMg from "/app/app/plugins/datadog.client.ts";
import inputmask_directive_client_bSkrajOSOC from "/app/app/plugins/inputmask-directive.client.ts";
import storyblok_renderer_aYvGZsX5Uz from "/app/app/plugins/storyblok-renderer.ts";
import storyblok_CaHfdxLkzD from "/app/app/plugins/storyblok.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/app/.nuxt/vuetify-nuxt-plugin.client.mjs";
import defaults_aRl2YLnTcb from "/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_KAumv2pUni,
  vuetify_client_hints_client_umMQneJmAK,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  plugin_hBLWEPkA3b,
  nuxt_plugin_6wEQMY3tee,
  vuetify_U7abQ5TEyK,
  preview_jcpfaQH7hz,
  vuetify_b8nXvi6Hh9,
  datadog_client_7ANTQbYUMg,
  inputmask_directive_client_bSkrajOSOC,
  storyblok_renderer_aYvGZsX5Uz,
  storyblok_CaHfdxLkzD,
  vuetify_nuxt_plugin_client_JOtFoHdxcp,
  defaults_aRl2YLnTcb
]